import Benefit from '../../components/benefit/benefit';
import Footer from '../../components/footer/footer';
import LandingHeader from '../../components/landingHeader/landingHeader';
import Partners from '../../components/partners/partners';
import Problem from '../../components/problem/problem';
import Marquee from '../../components/marquee/marquee';
import Solution from '../../components/solution/solution';
import Waitlist from '../../components/waitlist/waitlist';
import './landing.scss';

const Landing = () => {
  return (
    <div>
      <div className={'landing-container'}>
        <LandingHeader />
        <Partners />
        <Problem />
        <Solution />
        <Benefit />
        <Marquee />
        <Waitlist />
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
