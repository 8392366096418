import axios from 'axios';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Check from './../../assets/check.png';

import './waitlist.scss';

const Waitlist = () => {
  const REACT_APP_GOOGLE_SHEETS_SCRIPT = process.env.REACT_APP_GOOGLE_SHEETS_SCRIPT ?? '';

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  const perks = [
    {
      content: 'Early Access: Join our waitlist to get early access to the app.',
    },
    {
      content: 'Exclusive Offers: First-time users on the waitlist will receive exclusive benefits upon launch.',
    },
  ];

  const products = useMemo(() => ['SNPL', 'Zero FX Cashback Card', 'Zero FX Remittance', 'Wallet + AI Powered Analytics/Insights'], []);

  const submitForm = async ({ name, email, nationality, ...checkboxes }: any) => {
    const products = Object.values(checkboxes)
      .filter((checkbox) => checkbox !== false)
      .join(', ');

    const form = new FormData();
    form.append('Timestamp', moment().utcOffset('+0300').format('DD/MM/YYYY HH:mm:ss'));
    form.append('Name', name);
    form.append('Email', email);
    form.append('Nationality', nationality);
    form.append('Products', products);

    reset();

    await axios.post(REACT_APP_GOOGLE_SHEETS_SCRIPT, form);
  };

  return (
    <React.Fragment>
      <div className={'waitlist-container'} id={'waitlist'}>
        <div className={'waitlist'}>
          <div className={'waitlist-left'}>
            <div className={'waitlist-left-title'}>BE FIRST IN LINE</div>
            <span className={'waitlist-left-header'}>Be the First to Experience Shariah-Compliant Send Now, Pay Later</span>
            <span className={'waitlist-left-body'}>
              Sinder is launching soon. Be the first to access a seamless, Shariah-compliant solution for sending money home and managing your finances without the usual fees.
            </span>
            <div className={'waitlist-left-perks'}>
              {perks.map((perk, index) => (
                <div key={index} className={'waitlist-left-perks-container'}>
                  <img className={'waitlist-left-perks-icon'} src={Check} alt={'check-icon'} />
                  <span className={'waitlist-left-perks-content'}>{perk.content}</span>
                </div>
              ))}
            </div>
          </div>

          <form className={'waitlist-right'} onSubmit={handleSubmit(submitForm)}>
            <div className={'waitlist-right-container'}>
              <label className={'waitlist-right-container-label'}>Full Name</label>
              <input className={'waitlist-right-container-input'} {...register('name', { required: true })} />
            </div>

            <div className={'waitlist-right-container'}>
              <label className={'waitlist-right-container-label'}>Email</label>
              <input
                className={'waitlist-right-container-input'}
                {...register('email', { required: true, pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: 'Please enter a valid email address.' } })}
              />
            </div>

            <div className={'waitlist-right-container'}>
              <label className={'waitlist-right-container-label'}>Nationality</label>
              <input className={'waitlist-right-container-input'} {...register('nationality', { required: true })} />
            </div>

            <div className={'waitlist-right-container'}>
              <label className={'waitlist-right-container-label'}>What products are you interested in?</label>
              {products.map((product) => (
                <div className={'waitlist-right-container-checkbox'}>
                  <input className={'waitlist-right-container-checkbox-input'} value={product} type={'checkbox'} {...register(product)} />
                  <span className={'waitlist-right-container-checkbox-title'}>{product}</span>
                </div>
              ))}
            </div>

            <button className={`waitlist-right-join ${!isValid && 'waitlist-right-join-disabled'}`} disabled={!isValid}>
              Join the Waitlist
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Waitlist;
